import React, { useEffect } from "react";

import { navigate } from "@utils/navigate";
import { createSettingsView, ViewComponent } from "@settings/view";
import DeliveryMethods from "./delivery-methods";

const Settings: ViewComponent = (props) => {

    useEffect(() => {
        navigate("/settings/delivery-methods", {
            replace: true,
        });
    }, []);

    return (
        <DeliveryMethods {...props} />
    );
};

export default createSettingsView(Settings, {
    title: "Settings",
});